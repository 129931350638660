import { CCApiService } from './api-service';
import { UIConfig } from './interfaces';

export class ConfigService {
  static readonly CONFIG_API = '/ui-config/props.json';

  static ApiService = new CCApiService();

  static _uiConfig: UIConfig;

  static getUIConfig(): UIConfig {
    return this._uiConfig;
  }

  static async initUIConfig() {
    if (!this._uiConfig) {
      return this.ApiService.performFetch(ConfigService.CONFIG_API).then(uiConfig => {
        this._uiConfig = uiConfig;
      });
    } else {
      return Promise.resolve(this._uiConfig);
    }
  }
}
